import React, { useState } from 'react'

const SimpleOptionSelector = ({od, data, selector, handler, object}) => {
    const [option, setOption] = useState(null)

    const handleClick = (data) => {
        setOption(data)
    }

    const checkPrice = (simple_option) => {
        if(object[simple_option.uuid] >= 1){
            return (object[simple_option.uuid] * simple_option.price).toFixed(2)
        }
        return parseFloat(simple_option.price).toFixed(2)
    }

    const closeModal = () => {
        setOption(null)
    }

    return (
        <>
            {data ? (
                <>
                    <div className="fancy-small-card-wrapper">
                        <div className={od ? "fancy-small-card-list-od" : "fancy-small-card-list"}>
                            {data.map((category, i) => {
                                return (
                                    <div className={od ? "pres-card-od" : "pres-card"} onClick={() => handleClick(category)} key={i}>
                                        <div className="pres-card-header">
                                            <img src={category.picture} className="img-cover" alt={category.title} />
                                        </div>
                                        <div className="pres-card-content">
                                            <p className="big-text bold-text center-text white-text">{category.title}</p>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    {option ? (
                        <div className='side-modal-overlay' onClick={closeModal}></div>
                    ): ""}
                    <div className={option ? "side-modal side-modal-active" : "side-modal"}>
                        {option ? (
                            <>  
                                <div className='side-modal-header'>
                                    <div className='side-modal-header-img'>
                                        <img src={option.picture} alt={option.title} className='img-cover'/>
                                    </div>
                                    <div className='side-modal-icon' onClick={closeModal}>
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                            <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                                        </svg>
                                    </div>
                                    <div className='flex-col-center full-height'>   
                                        <h3 className='side-modal-header-title big-text bold-text white-text'>
                                            {option.title}
                                        </h3>
                                    </div>
                                </div>
                                <div className='side-modal-content pad-t-xl'>
                                    <div className='gutter'>
                                        <div className='grid-r-2-3'>
                                            {option.simple_options.map((simple_option, i) => {
                                                return (
                                                    <div key={i} className='grid-item'>
                                                        <div className='item-card'>
                                                            <div className='item-card-header'>
                                                                <img src={simple_option.picture} alt={simple_option.title} />
                                                            </div>
                                                            <div className='item-card-content'>
                                                                <p className='large-text regular-text black-text center-text pad-t-m'>
                                                                    {simple_option.title}
                                                                </p>
                                                            </div>
                                                            <div className='item-card-footer pad-b-l'>
                                                                <p className='large-text bold-text black-text pad-t-s pad-b-m'>
                                                                    {selector ? checkPrice(simple_option) : simple_option.price} €
                                                                </p>
                                                                {selector ? (
                                                                    <input type="number" min={0} value={object[simple_option.uuid] || ""} name="quantity" id={simple_option.uuid} onChange={(e) => handler(simple_option.uuid, e.target.value, simple_option)} className="input input-number"/>
                                                                ) : ""}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : ""}
                    </div>
                </>
            ) : (
                <div className='flex-col-center'>
                    <p className='large-text regular-text black-text'>Une erreur c'est produite lors du chargement des options simples.</p>
                </div>
            )}
        </>
    )
}

export default SimpleOptionSelector