import React from 'react'
import { motion, /* useScroll */ } from "framer-motion";
import { Link } from 'react-router-dom';


const Progress = ({step, maxStep, changer}) => {
  const getStep = () => {
    let fullpath = window.location.href
    let splitUrl = fullpath.split('/')
    let lastParams = splitUrl[splitUrl.length-1].split("-")
    let currentStep = lastParams[lastParams.length-1]
    return parseInt(currentStep)
  }

  const checkTitle = () => {
    let title = {
      'title' : "",
      'content' : "",
      'link': ""
    }
    if(getStep() === 1){
      title.title = "Information générale"
      title.content = "Renseigner les informations nécessaires concernant l'évènement."
      title.link = "/demande/etape-1"
    }else if(getStep() === 2){
      title.title = "Choix de la formule"
      title.content = "Sélectionner la formule que vous désirez."
      title.link = "/demande/etape-2"
    }else if(getStep() === 3){
      title.title = "Sélection des options"
      title.content = "Ajouter les options qui vous font envie."
      title.link = "/demande/etape-3"
    }else if(getStep() === 4){
      title.title = "Résumer de la demande"
      title.content = "Vérifier toutes les informations avant de confirmer vos choix"
      title.link = "/demande/etape-4"
    }else if(getStep() === 5){
      title.title = "Confirmation de la demande"
      title.content = "Votre demande a bien été prise en compte nous vous recontacterons dans les plus bref délais."
      title.link = "/demande/etape-5"
    }
    return title
  }

  const progressButton = () => {
    let content = [];
    for (let i = 1; i < maxStep + 1; i++) {
      if(getStep() >= i){
        content.push(<Link to={`/demande/etape-${i}`} key={i} className={'p-button-active progress-button'} style={{left: (((i)*100) / maxStep) - 14 + "%"}}></Link>);
      }else{
        content.push(<div key={i} className={'progress-button'} style={{left: (((i)*100) / maxStep) - 14 + "%"}}></div>);
      }
    }
    return content;
  }

  return (
    <>
      <div className='flex-col-center'>
        <h1 className="title-text bold-text white-text center-text pad-b-m">
          {checkTitle().title}
        </h1>
        <p className='large-text regular-text white-text center-text pad-b-l'>
        {checkTitle().content}
        </p>
      </div>
      <div className='progress-wrapper'>
        <div className='progress-bar'>
          <motion.div className='progress' style={{width: ((getStep()*100) / maxStep) - 12 + "%"}}></motion.div>
        </div>
        {progressButton()}
      </div>
    </>
  )
}

export default Progress