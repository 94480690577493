import React, { useEffect, useState } from 'react'
import { motion } from "framer-motion";

const VARIATION = ['button', 'page-button', 'slide-page-button'];

const ImageSlider = ({images, data, setter}) => {
    const checkVariation = VARIATION.includes(data.variation) ? data.variation : VARIATION[0]
    const max = images.length -1

    const closeModal = () => {
        setter({
            display : false,
            variation : "button",
            item: ""
        })
    }

    const prev = (current) => {
        const currentIndex = images.findIndex((image) => image.uuid === current.item.uuid)
        let desireIndex = currentIndex - 1

        if (desireIndex < 0){
            desireIndex = max
        }

        setter({
            display : true,
            variation : "button",
            item: images[desireIndex]
        })
    }

    const next = (current) => {
        const currentIndex = images.findIndex((image) => image.uuid === current.item.uuid)
        let desireIndex = currentIndex + 1

        if (desireIndex > max){
            desireIndex = 0
        }

        setter({
            display : true,
            variation : "button",
            item: images[desireIndex]
        })
    }

    const checkButton = () => {
        switch(checkVariation){
            case 'button':
                return (
                    <>
                        <div className='img-slider-btn'>
                            <div className='img-slider-icon' onClick={() => prev(data)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                                </svg>
                            </div>
                            <div className='img-slider-icon' onClick={() => next(data)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
                                </svg>
                            </div>                        
                        </div>                        
                    </>
                );
            case 'page-button':
                return (
                    <>
                        <div className='slider-btn'>
                            <div className='icon-medium bg-icon-white' onClick={() => prev(data)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                                </svg>
                            </div>
                            <div className='icon-medium bg-icon-white' onClick={() => next(data)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
                                </svg>
                            </div>                        
                        </div>                        
                    </>
                );
            case 'slide-page-button':
                return (
                    <>
                        <div className='slider-btn'>
                            <div className='icon-medium bg-icon-white' onClick={() => prev(data)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                                </svg>
                            </div>
                            <div className='icon-medium bg-icon-white' onClick={() => next(data)}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                                    <path d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"/>
                                </svg>
                            </div>                        
                        </div>                        
                    </>
                );
        }
    }

    return (
        <>
            <motion.div id="img-slider-overlay" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "linear", duration: 0.3 }} style={data.display ? {display:'block'} : {display: 'none'}} className='img-slider-overlay' onClick={closeModal}></motion.div>
            <motion.div id="img-slider" initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ type: "linear", duration: 0.3 }} style={data.display ? {display:'flex'} : {display: 'none'}} className='img-slider-wrapper'>
                <div className='img-slider-icon-medium' onClick={closeModal}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                        <path d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" />
                    </svg>
                </div>
                <div className='img-slider'>
                    <img className="img-contain" src={data.item.media} alt={data.item.alt} />
                </div>
                {checkButton()}
            </motion.div>
        </>
    )
}

export default ImageSlider