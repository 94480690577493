import { React, useCallback, useContext, useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";
import { OrderContext } from "./order";
import axiosProvider from "../../core/axios";
import SimpleOptionSelector from "../../components/simpleOptionSelector";

const Step3 = () => {
    const [order, setOrder, error, setError] = useContext(OrderContext);
    const [data, setData] = useState(null);
    const [saveOrder] = useOutletContext();
    const [quantities, setQuantities] = useState({});
    const [checkedOptions, setCheckedOptions] = useState({});

    const fetchData = useCallback(async () => {
        await axiosProvider.get("/shop/option-set/").then((response) => {
            setData(response.data)
        }).catch((error) => {
            setError({
                error : "Erreur de récupération de données", 
                message:"Une erreur c'est produite lors de la récupération des données."
            })
        });
    }, [setError]);

    useEffect(() => {
        fetchData()
    }, [fetchData])

    useEffect(() => {
        const newQuantities = {};
        const newCheckedOptions = {};
        if (order.step3.simple_options.length > 0) {
            order.step3.simple_options.forEach((elem) => {
                newQuantities[elem.simple_option.uuid] = elem.quantity;
            });
        }
        if (order.step3.option_pack.length > 0) {
            order.step3.option_pack.forEach((elem) => {
                newCheckedOptions[elem.uuid] = true;
            });
        }
        setQuantities(newQuantities);
        setCheckedOptions(newCheckedOptions);
    }, [order]);

    const handleQuantity = (uuid, value, data) => {
        const cleanData = {
            "quantity": value,
            "simple_option":data,
            "price" : value > 1 ? (value * data.price).toFixed(2) : parseFloat(data.price).toFixed(2)
        }
        if(order.step3.simple_options.find((elem) => elem.simple_option.uuid === uuid)){
            if(parseInt(value) === 0){
                const index = order.step3.simple_options.findIndex((elem) => elem.simple_option.uuid === uuid)
                order.step3.simple_options.splice(index, 1)
                setOrder({...order, step3: {...order.step3, simple_options: order.step3.simple_options}})
            }else{
                setOrder((prevOrder) => ({
                ...prevOrder,
                step3: {
                    ...prevOrder.step3,
                    simple_options: prevOrder.step3.simple_options.map((elem) =>
                    elem.simple_option.uuid === uuid ? { ...elem, quantity: value, price: value > 1 ? (value * data.price).toFixed(2) : parseFloat(data.price).toFixed(2)} : elem
                    ),
                },
                }));
            }
        }else{
            setOrder({...order, step3: {...order.step3, simple_options: [...order.step3.simple_options, cleanData]}})
        }
    }

    const handleCheck = (uuid, data) => {
        const checkbox = document.getElementById(uuid)
        const label = document.getElementById(uuid + "-label")
        if(order.step3.option_pack.find((elem) => elem.uuid === uuid)){
            const index = order.step3.option_pack.findIndex((elem) => elem.uuid === uuid)
            order.step3.option_pack.splice(index, 1)
            setOrder({...order, step3: {...order.step3, option_pack: order.step3.option_pack}})
            checkbox.checked = false
            label.classList.remove("fancy-card-simple-checked")
        }else{
            setOrder({...order, step3: {...order.step3, option_pack: [...order.step3.option_pack, data]}})
            checkbox.checked = true
            label.classList.remove("fancy-card-simple-checked")
            label.classList.add("fancy-card-simple-checked")
        }
    }

    return (
        <>
        {data ? (
            <>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ type: "linear", duration: 0.2 }}
                className="container bg-white"
            >
                <div className="spacer"></div>
                <h2 className="big-text bold-text black-text pad-b-xl">
                    Les options individuelles
                </h2>
                <SimpleOptionSelector od={true} data={data.simple_option_categories} selector={true} handler={handleQuantity} object={quantities}/>
                <h2 className="big-text bold-text black-text pad-b-xl pad-t-xl">
                    Les packs d'animations
                </h2>
                <div className="grid-3 pad-b-xl">
                    {data.option_packs.map((option_pack, i) => {
                        return (
                            <div className="grid-item" key={i}>
                                <label id={`${option_pack.uuid}-label`} htmlFor={option_pack.uuid} className={checkedOptions[option_pack.uuid] ? "fancy-card-simple fancy-card-simple-checked" : "fancy-card-simple"}>
                                <div className="fancy-card-simple-header">
                                    <div className="flex-col-center">
                                    <p className="big-text bold-text blue-text">{option_pack.title}</p>
                                    </div>
                                </div>
                                <div className="fancy-card-simple-content pad-b-xl">
                                    <div className="flex-col-start">
                                        <ul className="fancy-item-list">
                                            {option_pack.items.map((item, i) => {
                                                return (
                                                    <li className="fancy-card-item" key={i}>
                                                        <div className="circle-dot circle-blue"></div>
                                                        <span className="large-text regular-text r-black-text pad-l-s">
                                                            <p className="large-text regular-text r-black-text pad-l-s">
                                                                <b>{item.quantity}</b> {item.simple_option.title}
                                                            </p>
                                                        </span>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="fancy-card-simple-footer pad-b-xl">
                                    <p className="big-text bold-text blue-text">{option_pack.price} €</p>
                                </div>
                                </label>
                                <input type="checkbox" name={option_pack.uuid} id={option_pack.uuid} className="fancy-card-simple-checker" onChange={() => handleCheck(option_pack.uuid, option_pack)} checked={checkedOptions[option_pack.uuid] || false}/>
                            </div>
                        )
                    })}
                </div>
                <div className="flex-row-between pad-b-xl">
                <a href={"/demande/etape-2"} className="btn btn-default btn-large btn-cream">
                    Précédent
                </a>
                <div onClick={() => saveOrder(3)} className="btn btn-default btn-large btn-current" >
                    Suivant
                </div>
                </div>
                <div className="spacer"></div>
            </motion.div>
            </>
        ) : (
            <>
                <div className="container-primary bg-white">
                    <div className="flex-full-center full-height">
                        <div className="icon-big">
                            <motion.svg xmlns="http://www.w3.org/2000/svg" 
                            viewBox="0 0 422 422"
                            animate={{rotate:[0, 360] }}
                            transition={{
                                type: "linear",
                                duration: 0.5, 
                                repeat: Infinity
                            }}>
                                <g id="loading_icon" transform="translate(4730 -3101)">
                                    <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                    <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                </g>
                            </motion.svg>
                        </div>
                    </div>
                </div>
            </>
        )}
        
        </>
    );
};

export default Step3;
