import axios from "axios";

const axiosProvider = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    headers: localStorage.getItem("access_token") ? {
        Authorization: `JWT ${localStorage.getItem("access_token")}`,
        "Content-Type": "application/json",
    }:{
        "Content-Type": "application/json",
    },
});

// header debug
axiosProvider.interceptors.request.use((config) => {
    console.log('Request config:', config);
    return config;
});

axiosProvider.interceptors.response.use(
    (response) => response,
    async (error) => {
        if (!error.response) {
            alert(
                "Erreur réseau ou serveur. Vérifiez les configurations CORS et la connectivité."
            );
            return Promise.reject(error);
        }

        if (error.response.status === 401 && error.response.data.code === 'token_not_valid') {
            const refreshToken = localStorage.getItem("refresh_token");

            if (refreshToken) {
                try {
                    const { data } = await axios.post(
                        `${process.env.REACT_APP_API_BASE_URL}/refresh/`,
                        { refresh: refreshToken },
                        { withCredentials: true }
                    );
                    localStorage.setItem("access_token", data.access);
                    localStorage.setItem("refresh_token", data.refresh);

                    error.config.headers.Authorization = `JWT ${data.access}`;
                    return axiosProvider.request(error.config);
                } catch (refreshError) {
                    console.error("Erreur lors du rafraîchissement du token:", refreshError);
                    window.location.href = "/";
                }
            } else {
                window.location.href = "/";
            }
        }

        return Promise.reject(error);
    }
);

export default axiosProvider;