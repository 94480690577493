import React from 'react'

const NotFound = () => {
  return (
    <>
      <div className='container flex-col-center bg-white'>
        <div className='gutter full-height'>
          <div className='flex-col-center'>
            <h1 className='title-text bold-text black-text pad-b-xl'>404 Page non trouver</h1>
            <p className='large-text regular-text black-text center-text pad-b-xl'>Vous essayez d'accéder à une page qui n'existe pas.</p>
            <a href="/" className="btn btn-bordered btn-large btn-great">Retour à l'accueil</a>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotFound