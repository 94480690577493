import React, { useRef, useContext, useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import axiosProvider from "../../core/axios";
import Metadata from "../../components/metadata";
import { imageZoomVariants } from "../../components/motionVariant";

const FormulaDetail = () => {
    const scrollRef = useRef(null);
    const navigate = useNavigate();
    const { slug } = useParams();
    const { theme } = useContext(ThemeContext);
    const [data, setData] = useState(null);

    const fetchData = useCallback(async () => {
        await axiosProvider.get(`/shop/formulas/${slug}`).then((response) => {
            setData(response.data);
        }).catch((error) => {
            navigate("/404");
        });
    }, [navigate, slug]);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchData();
    }, [fetchData]);

    const checkTheme = () => {
        return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
    };

    const returnAction = () => {
        navigate(-1);
    };

    return (
        <>
            {data ? (
                <>
                    {/*-------------------------------- SEO --------------------------------*/}
                    <Metadata metadata={data.metadata} type="article" />
                    {/*-------------------------------- Back button --------------------------------*/}
                    <div className="back-btn-wrapper" onClick={returnAction}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                            <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 246.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"/>
                        </svg>
                    </div>
                    {/*-------------------------------- Banner --------------------------------*/}
                    <div className="container-x-small bg-white" ref={scrollRef}>    
                        <div className="ban-img-wrapper-small">
                            <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 100, repeat: Infinity,}} className="ban-img">
                                <img className="img-cover" src="/images/platine.jpg" alt="Banner" />
                            </motion.div>
                        </div>
                        <div className="ban-content-small">
                            <div className="gutter-large">
                                <div className="grid-2-r-l">
                                    <div className="grid-item">
                                        <div className="flex-col">
                                            <div className="flex-row-center full-width">
                                                <h1 className="title-text bold-text white-text back-trans-black"><span className="tag tag-big tag-great marg-r-s" >{data.formula.title}</span> {data.formula.pack}</h1>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid-item">
                                        <div className="full-width">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="back-container">
                            <svg xmlns="http://www.w3.org/2000/svg" id="back-shape" viewBox="0 0 1920 780.981">
                                <g id="shape_normal" transform="translate(0 -300)">
                                    <path id="last" d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.47"/>
                                    <path id="second" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z" transform="translate(0 1216.781)" opacity="0.65"/>
                                    <path id="first" d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z" transform="translate(0 1216.781)" fill={checkTheme()}/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    {/*-------------------------------- Content --------------------------------*/}
                    <div className="container bg-white">
                        <div className="spacer"></div>
                        <div className="gutter-large">
                            <p className="large-text regular-text black-text">
                                {data.formula.short_desc}
                            </p>
                        </div>
                        <div className="spacer"></div>
                        <div className="gutter-large">
                            <div className="flex-col-center">
                                <div className="flex-row-center pad-t-l pad-b-xl">
                                    <div className="icon-medium bg-icon-black">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/>
                                        </svg>
                                    </div>
                                    <h2 className="big-text bold-text black-text pad-l-m">Composition</h2>
                                </div>
                            </div>
                            <div className="flex-col pad-t-l pad-b-xl">
                                <ul className="grid-3">
                                    {data.items.map((item, i) => {
                                        return (
                                            <div className="grid-item" key={i}>
                                                <div className="flex-row-start medium-width">
                                                    <div className="circle-dot circle-blue"></div>
                                                    <p className="large-text regular-text black-text pad-l-s">
                                                        <b>{item.quantity}</b> {item.title}
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </ul>
                            </div>
                        </div>
                        <div className="spacer"></div>
                        <div className="gutter-large">
                            <div className="article black-text pad-b-l" dangerouslySetInnerHTML={{ __html: data.formula.description }}>
                            </div>
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="container-primary bg-white">
                        <div className="flex-full-center full-height">
                            <div className="icon-big">
                                <motion.svg xmlns="http://www.w3.org/2000/svg" 
                                viewBox="0 0 422 422"
                                animate={{rotate:[0, 360] }}
                                transition={{
                                    type: "linear",
                                    duration: 0.5, 
                                    repeat: Infinity
                                }}>
                                    <g id="loading_icon" transform="translate(4730 -3101)">
                                        <path className="loading" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                        <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                                    </g>
                                </motion.svg>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default FormulaDetail