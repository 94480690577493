import React, { useRef, useContext, useState, createContext, useEffect } from "react";
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { motion } from "framer-motion";
import { ThemeContext } from "../../App";
import Progress from "./progress";
import Metadata from "../../components/metadata";
import axiosProvider from "../../core/axios";
import Modal from "../../components/modal";
import { imageZoomVariants } from "../../components/motionVariant";

export const OrderContext = createContext(null)

const Order = () => {
  const scrollRef = useRef(null);
  const navigate = useNavigate()
  const { theme } = useContext(ThemeContext);
  const [spinner, setSpinner] = useState(false)
  const maxStep = 5;
  const [step4Modal, setStep4Modal] = useState({
    display : false,
    variation : "ok",
    title: "",
    content: "",
    subContent: "",
    type: ""
})
  const [error, setError] = useState({
    error: "",
    message: ""
  })
  const [order, setOrder] = useState({
      step1: {
        firstname: "",
        lastname: "",
        email: "",
        phone: "",
        event: {
          type: "",
          adress: "",
          postal_code: "",
          city: "",
          date: "",
          start_hour: "",
          end_hour: "",
          sup_infos: "",
        }
      },
      step2: {
        formula: ""
      },
      step3: {
        simple_options: [],
        option_pack: [],
      }      
  })
  const [metadata] = useState({
    title : "Animer votre evenement - Profiter des services de Dj-Tdy",
    description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
    generator : "Made by Dj-TDY",
    keywords : "DJ,Dj à domicile,Fête,Mariages,Baptême,soirée, animation",
    canonical : "http://www.bestexemple.com/canonical",
    og_locale : "en_GB",
    og_locale_alternate : "fr_fr",
    og_type : "article",
    og_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
    og_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
    og_url : "https://www.thisisatest.com",
    og_image_url : "https://www.bestexemple.com/exempleimage.png",
    og_image_secure_url : "https://www.bestexemple.com/exempleimage.png",
    article_section : "Web development",
    article_publisher : "Test author",
    article_published_time : "2023-01-07T02:10:58.678Z",
    article_modified_time : "2023-01-07T02:10:58.678Z",
    twitter_title : "The Art of Python Web Development: Crafting Dynamic Digital Experiences",
    twitter_description : "Dive into the World of Python Web Development and Unleash the Power of Creativity and Functionality in Every Line of Code.",
    twitter_site : "@testuser",
    twitter_card : "summuary_test",
    twitter_image : "https://www.bestexemple.com/exempleimage.png",
    twitter_creator : "@testuser",
    twitter_cta : "En savoir plus",
    twitter_label_1 : "Ecrit par",
    twitter_data_1 : "DJ-Tdy Team",
    twitter_label_2 : "Temps de lecture",
    twitter_data_2 : "10 minutes",
  })

  useEffect(() => {
    window.scrollTo(0, 0)
    if(localStorage.getItem('order')){
      let json = JSON.parse(localStorage.getItem('order'))
      setOrder(json)
    }else{
      localStorage.setItem('order', JSON.stringify(order))
    }
  }, [])

  const checkTheme = () => {
    return theme === "light" ? "var(--regular-white)" : "var(--dark-grey)";
  };

  const handleForm = (e) =>{
    e.preventDefault()
    setSpinner(true)
    axiosProvider.post('/shop/orders/create/', {
      order : order,
      elem_count : 1 + order.step3.simple_options.length + order.step3.option_pack.length
    }).then((response) => {
      localStorage.removeItem('order')
      navigate('/demande/etape-5')
    }).catch((error) => {
        setStep4Modal({
          display : true,
          variation : "ok",
          title: "Création de comande",
          content: "Erreur d'enregistrement",
          subContent: error.response.data.error,
          type: "error"
      })
    }).finally(() => {
      setSpinner(false);
  });
  }

  const saveOrder = (step, final=null) => {
    localStorage.setItem('order', JSON.stringify(order))
    navigate(`/demande/etape-${step+1}`)
  }



  const checkUrl = () => {
    let fullpath = window.location.href
    let splitUrl = fullpath.split('/')
    let lastParams = splitUrl[splitUrl.length-1]
    if(lastParams === "demande"){
      return true
    }
    return false
  }

  return (
    <>
      {/*-------------------------------- SEO --------------------------------*/}
      <Metadata metadata={metadata} type="page" />
      {spinner ? (
         <>
            <div className="container-primary bg-white">
                <div className="flex-full-center full-height">
                    <div className="icon-big">
                        <motion.svg xmlns="http://www.w3.org/2000/svg" 
                        viewBox="0 0 422 422"
                        animate={{rotate:[0, 360] }}
                        transition={{
                            type: "linear",
                            duration: 0.5, 
                            repeat: Infinity
                        }}>
                            <g id="loading_icon" transform="translate(4730 -3101)">
                                <path className="loading-cream" id="Subtraction_2" data-name="Subtraction 2" d="M436.543,212.707h0l-72.556-.1c.008-.575.012-1.179.012-1.795A128.8,128.8,0,0,0,275.063,88.184V13.247l.422.086.134.027a200.468,200.468,0,0,1,72.07,30.326,202.154,202.154,0,0,1,73.023,88.677A200.476,200.476,0,0,1,432.457,170.2a203.034,203.034,0,0,1,4.1,40.62c0,.62,0,1.255-.009,1.889Z" transform="translate(-4744.552 3094.183)" />
                                <circle id="Ellipse_25" data-name="Ellipse 25" cx="211" cy="211" r="211" transform="translate(-4730 3101)" fill="none"/>
                            </g>
                        </motion.svg>
                    </div>
                </div>
            </div>
        </>
      ) : (
        <>
          {/*-------------------------------- Banner --------------------------------*/}
          <div className="container-x-small bg-white" ref={scrollRef}>
            <div className="ban-img-wrapper-small">
              <motion.div variants={imageZoomVariants} initial={"initial"} whileInView={'animate'} transition={{duration: 100, repeat: Infinity,}} className="ban-img">
                <img className="img-cover" src="/images/platine-5.jpg" alt="Banner" />
              </motion.div>
            </div>
            <div className="ban-content-small">
              <div className="gutter-x-large">
                <div className="grid-1">
                  <div className="grid-item">
                    <div className="full-width">
                      {checkUrl() ? (
                        <>
                          <div className='flex-col-center'>
                            <h1 className="title-text bold-text white-text center-text pad-b-m">
                              Passer votre commande en toute facilité
                            </h1>
                            <Link to={"/demande/etape-1"} className="btn btn-default btn-large btn-current">Accéder au configurateur</Link>
                          </div>
                        </>
                      ): (
                        <Progress maxStep={maxStep} />
                      )}
                    </div>
                  </div>
                  <div className="grid-item">
                    <div className="full-width"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="back-container">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="back-shape"
                viewBox="0 0 1920 780.981"
              >
                <g id="shape_normal" transform="translate(0 -300)">
                  <path
                    id="last"
                    d="M0-214.839s873.847,174.089,1353.847-21.4S1920-916.781,1920-916.781V-135.8H0Z"
                    transform="translate(0 1216.781)"
                    opacity="0.47"
                  />
                  <path
                    id="second"
                    d="M0-214.839s873.847,99.089,1353.847-21.4S1920-806.781,1920-806.781V-135.8H0Z"
                    transform="translate(0 1216.781)"
                    opacity="0.65"
                  />
                  <path
                    id="first"
                    d="M0-214.839s873.847,99.089,1353.847-21.4S1920-696.781,1920-696.781V-135.8H0Z"
                    transform="translate(0 1216.781)"
                    fill={checkTheme()}
                  />
                </g>
              </svg>
            </div>
          </div>
          {/*-------------------------------- Step form --------------------------------*/}
          <div className="container bg-white">
            <div className="gutter-large">
              {checkUrl() ? (
                <>
                  <div className="spacer"></div>
                  <div className="flex-col-start">
                    <h2 className="big-text bold-text black-text pad-b-m">Quelques explications ?</h2>
                    <p className="medium-text regular-text black-text pad-b-xl">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed nec placerat erat,quis condimentum libero. 
                    Duis aliquet nulla id lectus pellentesque, ac pretium nulla commodo.
                    Aenean pulvinar justo bibendum erat mollis vestibulum. 
                    Nunc erat purus, ornare sit amet tincidunt sed, rutrum vel mauris. 
                    Integer quis lectus lacus. Phasellus mi diam, vehicula quis tristique ac, 
                    dictum vitae mauris. Quisque id tristique erat, vel sodales mauris.
                    </p>
                    <h3 className="large-text bold-text black-text pad-b-m">Etape 1</h3>
                    <p className="medium-text regular-text black-text pad-b-xl">
                      La première partie du configurateur ce concentre sur la récupération d'information concernant les coordonées du contact et les informations clés de l'évenement en question. Dans cette partie, il est préferable de renseigner le maximum d'informations afin de profité d'une experience agréable.
                    </p>
                    <h3 className="large-text bold-text black-text pad-b-m">Etape 2</h3>
                    <p className="medium-text regular-text black-text pad-b-xl">
                      La Deuxième partie vous permettrat de choisir la <b>formule de dj</b> adapté à vos envies et ainsi profiter d'un moment inoubliable animer par un professionnel.
                    </p>
                    <h3 className="large-text bold-text black-text pad-b-m">Etape 3</h3>
                    <p className="medium-text regular-text black-text pad-b-xl">
                      La Deuxième partie vous permettrat de choisir la <b>formule de dj</b> adapté à vos envies et ainsi profiter d'un moment inoubliable animer par un professionnel.
                    </p>
                    <h3 className="large-text bold-text black-text pad-b-m">Etape 4</h3>
                    <p className="medium-text regular-text black-text pad-b-xl">
                      La Deuxième partie vous permettrat de choisir la <b>formule de dj</b> adapté à vos envies et ainsi profiter d'un moment inoubliable animer par un professionnel.
                    </p>
                    <h3 className="large-text bold-text black-text pad-b-m">Etape 5</h3>
                    <p className="medium-text regular-text black-text pad-b-xl">
                      La Deuxième partie vous permettrat de choisir la <b>formule de dj</b> adapté à vos envies et ainsi profiter d'un moment inoubliable animer par un professionnel.
                    </p>
                    <div className="flex-col-center pad-b-xl">
                      <Link to={"/demande/etape-1"} className="btn btn-large btn-bordered btn-current">Animer mon évenement</Link>
                    </div>
                  </div>
                  <div className="spacer"></div>
                </>
              ): (
                <>
                   <form className="" onSubmit={handleForm}>
                    <OrderContext.Provider value={[order, setOrder, error, setError]}>
                      <Outlet context={[saveOrder]}/>
                    </OrderContext.Provider>
                  </form>
                </>
              )}
            </div>
            <Modal data={step4Modal} setter={setStep4Modal}>
            </Modal>
          </div>
        </>
      )}
      
    </>
  );
};

export default Order;
